import Vue from 'vue';
import axios from 'axios';
import MobileMenu from '@vjs/components/MobileMenu/MobileMenu.vue';
import Trans from '@common/plugins/Translator';
import quasarApp from '@quasar/install';
import store from '@vjs/store';

Vue.prototype.$axios = axios;
Vue.use(Trans);
Vue.use(quasarApp);

if (document.getElementById('v-adaptive-menu-app')) {
  if (window.core_project) {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': window.core_project.csrfToken || '',
      'Accept-Language': window.core_project.locale,
    };
  } else {
    console.log('No window.core_project! You\'re probably testing static components');
  }
  window.vMobileMenu = new Vue({
    el: '#v-adaptive-menu-app',
    components: { MobileMenu },
    store,
  });
}

import sentry from '@vendor/sentry';
import Translator from '@common/plugins/Translator';
import { eventBus } from '@common/mixins';
import store from '@app_jarys/store';
import axios from 'axios';
import plugin from '@common/plugins';

import Inputmask from 'inputmask';

export default function (Vue) {
  plugin(Vue);
  const inputmaskPlugin = {
    install(_Vue, options) {
      _Vue.directive('mask', {
        bind(el, binding) {
          Inputmask(binding.value).mask(el);
        },
      });
    },
  };
  Vue.use(inputmaskPlugin);

  sentry(Vue, 'https://0ec06c833d4d44bbbc95f4ff53444691@sentry.services.snation.kz/6');

  Vue.use(Translator, { store, eventBus });
  Vue.prototype.$axios = axios;

  window.Vue = Vue;

  if (window.core_project) {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': window.core_project.csrfToken || '',
      'Accept-Language': window.core_project.locale,
    };
  } else {
    // eslint-disable-next-line no-console
    console.log('No window.core_project! You\'re probably testing static components');
  }
}

<template>
  <div class="row-sort-start">
    <div class="s-region-container">
      <div class="s-region-list">
        <h5 class="s-title s-upper s-bold pl-15">
          {{ trans('label.regions') }}
        </h5>
        <ul class="mt-10">
          <li
            v-for="(region, id) in regions"
            class="s-region-list__item-new"
            :class="[
              { 'is-active': region.active },
              { 'selected': region.id == getIdRegion }
            ]"
            :data-id="region.id"
            @click="get_department(id)"
          >
            <span>{{ region.name }}</span>
          </li>
        </ul>
      </div>
    </div>
    <transition name="opened">
      <div
        v-show="departments"
        class="s-region-container--right"
        :class="{opened: departmentsShow}"
        :style="{height: height}"
      >
        <div class="s-region-list">
          <h5 class="s-title s-upper s-bold mb-10 pl-25">
            {{ trans('label.departments') }}
          </h5>
          <div
            v-for="(department, id) in departments"
            class="s-region__department-container"
            :class="{'is-active': department.id == activeDepartment}"
          >
            <div class="row-sort-between row-sort--center">
              <div
                class="s-region-list__item-new department"
                :class="[
                  {'is-active': department.id == activeDepartment},
                  {'selected': department.id == getIdDepartment}
                ]"
                :data-id="department.id"
                @click="get_locality(id)"
              >
                {{ department.name }}
              </div>
              <transition name="fade">
                <a
                  v-show="department.id == activeDepartment"
                  :href="`${indexPage}?department=${department.id}`"
                  class="s-region-link__all-department"
                >
                  {{ trans('button.choose_department') }}
                </a>
              </transition>
            </div>
            <transition name="slide-fade">
              <div v-show="department.id == activeDepartment">
                <select
                  data-select-2
                  :data-select-id="id"
                >
                  <option value="">
                    {{ trans('placeholder.select_from_list') }}
                  </option>
                  <option
                    v-for="locality in department.localities"
                    :value="locality.id"
                  >
                    {{ locality.name }}
                  </option>
                </select>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    activeRegionId: {
      type: Number,
      default: null,
    },
    regionsAjaxUrl: {
      type: String,
      required: true,
    },
    departmentAjaxUrl: {
      type: String,
      required: true,
    },
    localityAjaxUrl: {
      type: String,
      required: true,
    },
    hasRegion: {
      type: String,
      default: false,
    },
    hasDepartment: {
      type: String,
      default: false,
    },
    hasLocality: {
      type: String,
      default: false,
    },
    getIdRegion: {
      type: Number,
      default: null,
    },
    getIdDepartment: {
      type: String,
      default: null,
    },
    getIdLocality: {
      type: String,
      default: null,
    },
    indexPage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      regions: null,
      departments: null,
      departmentsAnimation: 0,
      activeDepartment: null,
      activeLocalities: 0,
      locationExist: false,
      departmentExist: false,
      regionExist: false,
      departmentsShow: null,
      height: '',
    };
  },
  watch: {
    departmentsAnimation() {
      const vm = this;
      vm.departmentsShow = false;
      setTimeout(() => {
        vm.departmentsShow = true;
      }, 500);
      vm.height = `${(this.departments.length * 32) + 96}px`;
      vm.$nextTick(() => {
        $('[data-select-2]').select2({ width: '100%', minimumResultsForSearch: 0 });
        $('[data-select-2]').on('select2:select', function (evt) {
          window.location.href = `${vm.indexPage}?locality=${this.value}`;
        });
      });
    },
  },
  async mounted() {
    await this.$trans.add(['label', 'button', 'placeholder'])
    const vm = this;
    $.ajax({ // перенести на axios
      url: this.regionsAjaxUrl,
      dataType: 'JSON',
      success(response) {
        vm.regions = response;
        if (vm.hasRegion && !vm.regionExist) {
          vm.$nextTick(() => {
            if (vm.getIdRegion !== null) {
              const region = vm.regions.find(x => x.id == vm.getIdRegion);
              vm.get_department(vm.regions.indexOf(region));
            }
          });
          vm.regionExist = true;
        }
      },
    });
  },
  methods: {
    get_department(id) {
      const vm = this;

      _.each(vm.regions, (item) => {
        item.active = false;
      });
      const regionExist = vm.activeRegion == vm.regions[id].id;
      const region = vm.regions[id];
      vm.activeRegion = region.id;
      region.active = true;

      if (!region.department) {
        $.ajax({
          url: `${this.departmentAjaxUrl}?id=${vm.activeRegion}`,
          dataType: 'JSON',
          success(response) {
            vm.departments = response;
            if (!regionExist) {
              vm.departmentsAnimation++;
            }
            vm.$set(region, 'department', response);
            if (vm.hasDepartment && !vm.departmentExist) {
              vm.$nextTick(() => {
                const department = vm.departments.find(x => x.id == vm.getIdDepartment);
                vm.get_locality(vm.departments.indexOf(department));
              });
              vm.departmentExist = true;
            }
          },
        });
      } else {
        vm.$set(vm, 'departments', region.department);
        if (!regionExist) {
          vm.departmentsAnimation++;
        }
      }
    },
    get_locality(id) {
      const vm = this;
      _.each(vm.departments, (item) => {
        item.active = false;
      });

      const department = vm.departments[id];
      vm.activeDepartment = department.id;
      if (department.name.length > 16) {
        vm.height = `${(this.departments.length * 32) + 107}px`;
      } else {
        vm.height = `${(this.departments.length * 32) + 96}px`;
      }
      if (!department.localities) {
        $.ajax({
          url: `${this.localityAjaxUrl}?id=${vm.activeDepartment}`,
          dataType: 'JSON',
          success(response) {
            vm.$set(vm.departments[id], 'localities', response);
            vm.departments[id].localities = response;
            if (vm.hasLocality && !vm.LocationExist) {
              vm.$nextTick(() => {
                $(`[data-select-id=${id}]`).val(vm.getIdLocality).trigger('change');
              });
              vm.LocationExist = true;
            }
          },
        });
      }
      vm.$set(vm.departments[id], 'show', !vm.departments[id].show);
    },
  },
};
</script>

<style scoped>

</style>

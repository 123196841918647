<template>
  <ul
    v-if="pageCount>1"
    :class="containerClass"
  >
    <li
      v-if="firstLastButton"
      :class="[pageClass, firstPageSelected() ? disabledClass : '']"
    >
      <a
        :class="pageLinkClass"
        :tabindex="firstPageSelected() ? -1 : 0"
        @click="selectFirstPage()"
        @keyup.enter="selectFirstPage()"
        v-html="firstButtonText"
      />
    </li>
    <li
      v-if="!(firstPageSelected() && hidePrevNext)"
      :class="[pageClass, prevClass, firstPageSelected() ? disabledClass : '']"
    >
      <a
        :class="prevLinkClass"
        :tabindex="firstPageSelected() ? -1 : 0"
        @click="prevPage()"
        @keyup.enter="prevPage()"
        v-html="prevText"
      />
    </li>
    <li
      v-for="page in getPages()"
      :class="[pageClass, page.selected ? activeClass : '', page.disabled&&!page.breakView ? disabledClass : '', page.breakView ? breakViewClass: '']"
    >
      <a
        v-if="page.breakView"
        :class="[pageLinkClass, breakViewLinkClass]"
        tabindex="0"
      >
        <slot name="breakViewContent">{{ breakViewText }}</slot>
      </a>
      <a
        v-else-if="page.disabled"
        :class="pageLinkClass"
        tabindex="0"
      >{{ page.content }}</a>
      <a
        v-else
        :class="pageLinkClass"
        tabindex="0"
        @click="handlePageSelected(page.index + 1)"
        @keyup.enter="handlePageSelected(page.index + 1)"
      >{{ page.content }}</a>
    </li>
    <li
      v-if="!(lastPageSelected() && hidePrevNext)"
      :class="[pageClass, nextClass, lastPageSelected() ? disabledClass : '']"
    >
      <a
        :class="nextLinkClass"
        :tabindex="lastPageSelected() ? -1 : 0"
        @click="nextPage()"
        @keyup.enter="nextPage()"
        v-html="nextText"
      />
    </li>
    <li
      v-if="firstLastButton"
      :class="[pageClass, lastPageSelected() ? disabledClass : '']"
    >
      <a
        :class="pageLinkClass"
        :tabindex="lastPageSelected() ? -1 : 0"
        @click="selectLastPage()"
        @keyup.enter="selectLastPage()"
        v-html="lastButtonText"
      />
    </li>
  </ul>
</template>
<script>
// https://github.com/lokyoung/vuejs-paginate
export default {
  // name: 'pagination',
  props: {
    value: {
      type: Number,
      default: 1,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    forcePage: {
      type: Number,
    },
    clickHandler: {
      type: Function,
      default: () => { },
    },
    pageRange: {
      type: Number,
      default: 3,
    },
    marginPages: {
      type: Number,
      default: 1,
    },
    prevText: {
      type: String,
      default: 'Prev',
    },
    nextText: {
      type: String,
      default: 'Next',
    },
    breakViewText: {
      type: String,
      default: '…',
    },
    containerClass: {
      type: String,
    },
    pageClass: {
      type: String,
    },
    pageLinkClass: {
      type: String,
    },
    prevClass: {
      type: String,
    },
    prevLinkClass: {
      type: String,
    },
    nextClass: {
      type: String,
    },
    nextLinkClass: {
      type: String,
    },
    breakViewClass: {
      type: String,
    },
    breakViewLinkClass: {
      type: String,
    },
    activeClass: {
      type: String,
      default: 'active',
    },
    disabledClass: {
      type: String,
      default: 'disabled',
    },
    noLiSurround: {
      type: Boolean,
      default: false,
    },
    firstLastButton: {
      type: Boolean,
      default: false,
    },
    firstButtonText: {
      type: String,
      default: 'First',
    },
    lastButtonText: {
      type: String,
      default: 'Last',
    },
    hidePrevNext: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const vm = this;
    return {
      selected: 1,
    };
  },
  watch: {
    forcePage(val) {
      const vm = this;
      vm.$emit('input', val);
      vm.$set(vm, 'selected', val);
    },
  },
  methods: {
    getPages() {
      const items = {};
      if (this.pageCount <= this.pageRange) {
        for (let index = 0; index < this.pageCount; index++) {
          const page = {
            index,
            content: index + 1,
            selected: index === (this.selected - 1),
          };
          items[index] = page;
        }
      } else {
        const halfPageRange = Math.floor(this.pageRange / 2);

        const setPageItem = (index) => {
          const page = {
            index,
            content: index + 1,
            selected: index === (this.selected - 1),
          };

          items[index] = page;
        };

        const setBreakView = (index) => {
          const breakView = {
            disabled: true,
            breakView: true,
          };

          items[index] = breakView;
        };

        // 1st - loop thru low end of margin pages
        for (let i = 0; i < this.marginPages; i++) {
          setPageItem(i);
        }

        // 2nd - loop thru selected range
        let selectedRangeLow = 0;
        if (this.selected - halfPageRange > 0) {
          selectedRangeLow = this.selected - 1 - halfPageRange;
        }

        let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
        if (selectedRangeHigh >= this.pageCount) {
          selectedRangeHigh = this.pageCount - 1;
          selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
        }

        for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.pageCount - 1; i++) {
          setPageItem(i);
        }

        // Check if there is breakView in the left of selected range
        if (selectedRangeLow > this.marginPages) {
          setBreakView(selectedRangeLow - 1);
        }

        // Check if there is breakView in the right of selected range
        if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
          setBreakView(selectedRangeHigh + 1);
        }

        // 3rd - loop thru high end of margin pages
        for (let i = this.pageCount - 1; i >= this.pageCount - this.marginPages; i--) {
          setPageItem(i);
        }
      }
      return items;
    },
    handlePageSelected(selected) {
      if (this.selected === selected) {
        return;
      }
      this.$emit('input', selected);
      this.$set(this, 'selected', selected);
      this.clickHandler(selected);
    },
    prevPage() {
      if (this.selected <= 1) {
        return;
      }

      this.$emit('input', this.selected - 1);
      this.$set(this, 'selected', this.selected - 1);
      this.clickHandler(this.selected - 1);
    },
    nextPage() {
      if (this.selected >= this.pageCount) {
        return;
      }

      this.$emit('input', this.selected + 1);
      this.$set(this, 'selected', this.selected + 1);
      this.clickHandler(this.selected + 1);
    },
    firstPageSelected() {
      return this.selected === 1;
    },
    lastPageSelected() {
      return (this.selected === this.pageCount) || (this.pageCount === 0);
    },
    selectFirstPage() {
      if (this.selected <= 1) {
        return;
      }

      this.$emit('input', 1);
      this.$set(this, 'selected', 1);

      this.clickHandler(1);
    },
    selectLastPage() {
      if (this.selected >= this.pageCount) {
        return;
      }

      this.$emit('input', this.pageCount);
      this.$set(this, 'selected', this.pageCount);
      this.clickHandler(this.pageCount);
    },
  },
};
</script>
<style lang="css" scoped>
    a {
        cursor: pointer;
    }
</style>

import Vue from 'vue';
import VueTimeago from 'vue-timeago';
import axios from 'axios';
import VInsta from '@vjs/views/VInsta/VInsta.vue';

Vue.use(VueTimeago, {
  name: 'Timeago',
  locale: 'ru',
  locales: {
    ru: require('@vjs/views/VInsta/locales/ru/index.js'),
    kz: require('@vjs/views/VInsta/locales/kz/index.js'),
  },
});

Vue.prototype.$axios = axios;

if (document.getElementById('v-insta-app')) {
  if (window.core_project) {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': window.core_project.csrfToken || '',
      'Accept-Language': window.core_project.locale,
    };
  } else {
    console.log('No window.core_project! You\'re probably testing static components');
  }
  window.vInstaApp = new Vue({
    el: '#v-insta-app',
    components: { VInsta },
  });
}

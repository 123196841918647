var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row-sort-start"
  }, [_c('div', {
    staticClass: "s-region-container"
  }, [_c('div', {
    staticClass: "s-region-list"
  }, [_c('h5', {
    staticClass: "s-title s-upper s-bold pl-15"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('label.regions')) + "\n      ")]), _vm._v(" "), _c('ul', {
    staticClass: "mt-10"
  }, _vm._l(_vm.regions, function (region, id) {
    return _c('li', {
      staticClass: "s-region-list__item-new",
      class: [{
        'is-active': region.active
      }, {
        'selected': region.id == _vm.getIdRegion
      }],
      attrs: {
        "data-id": region.id
      },
      on: {
        "click": function click($event) {
          return _vm.get_department(id);
        }
      }
    }, [_c('span', [_vm._v(_vm._s(region.name))])]);
  }), 0)])]), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "opened"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.departments,
      expression: "departments"
    }],
    staticClass: "s-region-container--right",
    class: {
      opened: _vm.departmentsShow
    },
    style: {
      height: _vm.height
    }
  }, [_c('div', {
    staticClass: "s-region-list"
  }, [_c('h5', {
    staticClass: "s-title s-upper s-bold mb-10 pl-25"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.departments')) + "\n        ")]), _vm._v(" "), _vm._l(_vm.departments, function (department, id) {
    return _c('div', {
      staticClass: "s-region__department-container",
      class: {
        'is-active': department.id == _vm.activeDepartment
      }
    }, [_c('div', {
      staticClass: "row-sort-between row-sort--center"
    }, [_c('div', {
      staticClass: "s-region-list__item-new department",
      class: [{
        'is-active': department.id == _vm.activeDepartment
      }, {
        'selected': department.id == _vm.getIdDepartment
      }],
      attrs: {
        "data-id": department.id
      },
      on: {
        "click": function click($event) {
          return _vm.get_locality(id);
        }
      }
    }, [_vm._v("\n              " + _vm._s(department.name) + "\n            ")]), _vm._v(" "), _c('transition', {
      attrs: {
        "name": "fade"
      }
    }, [_c('a', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: department.id == _vm.activeDepartment,
        expression: "department.id == activeDepartment"
      }],
      staticClass: "s-region-link__all-department",
      attrs: {
        "href": "".concat(_vm.indexPage, "?department=").concat(department.id)
      }
    }, [_vm._v("\n                " + _vm._s(_vm.trans('button.choose_department')) + "\n              ")])])], 1), _vm._v(" "), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: department.id == _vm.activeDepartment,
        expression: "department.id == activeDepartment"
      }]
    }, [_c('select', {
      attrs: {
        "data-select-2": "",
        "data-select-id": id
      }
    }, [_c('option', {
      attrs: {
        "value": ""
      }
    }, [_vm._v("\n                  " + _vm._s(_vm.trans('placeholder.select_from_list')) + "\n                ")]), _vm._v(" "), _vm._l(department.localities, function (locality) {
      return _c('option', {
        domProps: {
          "value": locality.id
        }
      }, [_vm._v("\n                  " + _vm._s(locality.name) + "\n                ")]);
    })], 2)])])], 1);
  })], 2)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
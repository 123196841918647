import Vue from 'vue';
import store from '@vjs/store';
import bootstrap from '@app_jarys/bootstrap';
import componentsViews from '@app_jarys/components/Views';

bootstrap(Vue);
componentsViews(Vue);
if (document.getElementById('jarys-vue-container')) {
  const config = {};
  config.store = store;
  const vm = new Vue(config);
  vm.$mount('#jarys-vue-container');
}
